/* eslint-disable no-underscore-dangle */
import {
  MemoryRouter as Router,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import {
  Button,
  GridColumn,
  Grid,
  Icon,
  ButtonGroup,
  Popup
} from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import './App.css';
import { upload } from './actions/label-actions';
import { signOutUser } from './actions/user-actions';
import PrintModal from './components/PrintModal';
import UploadModal from './components/UploadModal';
import { useDropzone } from 'react-dropzone'
import { Amplify } from 'aws-amplify'
import { getCurrentUser } from './actions/user-actions';
import { ToastContainer } from 'react-toastify';

const fdLogo = require("./images/fd_square.png") as string
const logo = require("./images/southern_filters_logo.svg") as string
const isDev = process.env.IS_DEV

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: 'qvm3uahcg0jv8v6rmief56gaq',
      userPoolId: 'eu-west-2_sRefamVqC',
      loginWith: {
        oauth: {
          domain: 'sfom.auth.eu-west-2.amazoncognito.com',
          scopes: ['openid', 'email', 'phone'],
          redirectSignIn: [isDev ? 'https://localhost:1234' : 'https://sfom.cloudwrestlers.com'],
          redirectSignOut: [isDev ? 'https://localhost:1234' : 'https://sfom.cloudwrestlers.com'],
          responseType: 'code',
        }
      }
    }
  }
});

function SFOM(props) {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [labels, setLabels]: any = useState([]);

  useEffect(() => {
    window['sfom-branded-option'] = 'none' // none|fd
  }, [])

  async function handleFilesOpen(files) {
    const labels: any = await upload(files)
    let labelsData = labels && labels.data ? labels.data : []
    labelsData = labelsData.map((label) => {
      return {
        ...label,
        include: true,
        brandedOption: window['sfom-branded-option']
      }
    })
    setOpenUploadModal(true);
    setLabels(labelsData);
  }

  const { open } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: handleFilesOpen,
    multiple: true,
    maxFiles: 20
  });

  const handleUploadViaBrandedOption = (option: string) => {
    window['sfom-branded-option'] = option
    open()
  }

  return (<>
    <div className="sf-main">
      <img className="logo" alt="Southern Filters logo" src={logo} />
      <h2 className="sf-main-heading">Southern Filters Label Printing</h2>
      <Grid columns={2}>
        <GridColumn>
          <ButtonGroup fluid>
            <Button basic color='green' onClick={() => handleUploadViaBrandedOption('none')}>
              Upload
            </Button>
            <Popup position='bottom right' on='click' wide hoverable trigger={<Button basic color='green' icon='chevron down' />}>
              <Button size='tiny' basic compact color='purple' icon onClick={() => handleUploadViaBrandedOption('fd')}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <img src={fdLogo} style={{ width: '1.5em', marginRight: '0.3em' }} /> <span>Filters Direct Branded</span>
                </div>
              </Button>
            </Popup>
          </ButtonGroup>
        </GridColumn>
        <GridColumn>
          <Button fluid compact basic onClick={() => setOpenPrintModal(true)} color='blue'>
            Print
          </Button>
        </GridColumn>
      </Grid>
      <UploadModal open={openUploadModal} setOpen={setOpenUploadModal} labels={labels} setLabels={setLabels} />
      <PrintModal open={openPrintModal} setOpen={setOpenPrintModal} />
      <div className="sf-footer">
        <Button basic onClick={signOutUser} size='mini' compact><Icon name='sign out' />Logout ({props.username})</Button>
        <div><span><Icon name='help circle' />Max 20x 2MB .pdf</span> &bull; sfom build {(process.env.do_commit || 'dev').substring(0, 6)}</div>
      </div>
      {props.username === '' ? <div className='sf-blanket'></div> : null}
    </div>
    <ToastContainer />
  </>
  );
}

export default function App() {
  const [username, setUsername] = useState('');

  useEffect(() => {
    async function fetchUser() {
      const { user }: any = await getCurrentUser();
      setUsername(user.email)
    }

    fetchUser()
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SFOM username={username} />} />
      </Routes>
    </Router>
  );
}
