import {
  Button,
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Modal,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table, Checkbox
} from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { save } from '../actions/label-actions';
import { toast } from 'react-toastify';

export default function UploadModal(props) {
  const { labels, setLabels, setOpen } = props
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    setBusy(false)
  }, [labels])

  function handleItemInclude(index: number) {
    const labelsMutable = [...labels];
    const labelMutable = labels[index]
    labelMutable.include = !labelMutable.include
    labelsMutable[index] = labelMutable
    setLabels(labelsMutable)
  }

  const multiUnique = Object.keys(labels.reduce(function (r, a) {
    r[a._multiUnique] = r[a._multiUnique] || []
    r[a._multiUnique].push(a)
    return r
  }, Object.create(null)))

  async function handleItemsSave() {
    setBusy(true)
    const filtered = labels.filter((label: any) => label.include === true)
    try {
      await save(filtered)
      setTimeout(() => {
        props.setOpen(false)
        setBusy(false)
        toast.success(`Saved ${multiUnique.length} Order${multiUnique.length > 1 ? 's' : ''}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        })
      }, 800)
    } catch (e) {
      setBusy(false)
    }
  }

  return (<Modal
    onClose={() => props.setOpen(false)}
    onOpen={() => props.setOpen(true)}
    open={props.open}
    size='large'
  >
    <ModalHeader>Found {labels.length} items in {multiUnique.length} order{multiUnique.length > 1 ? 's' : ''}</ModalHeader>
    <ModalContent scrolling>
      <ModalDescription>
        <Table basic>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Include</TableHeaderCell>
              <TableHeaderCell>Type</TableHeaderCell>
              <TableHeaderCell>Info</TableHeaderCell>
              <TableHeaderCell>Consignment</TableHeaderCell>
              <TableHeaderCell>Customer</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {labels.map((label: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox label='' checked={label.include} onChange={() => handleItemInclude(index)} />
                  </TableCell>
                  <TableCell>
                    <b>{label._filterType}</b>
                  </TableCell>
                  <TableCell>{label._filterInfo}</TableCell>
                  <TableCell>{label._consignment}</TableCell>
                  <TableCell>{label._customer}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ModalDescription>
    </ModalContent>
    <ModalActions>
      <Button disabled={busy} loading={busy} basic color="blue" onClick={() => handleItemsSave()}>
        Save {labels.filter((label) => label.include).length} items
      </Button>
      <Button basic onClick={() => setOpen(false)}>Cancel</Button>
    </ModalActions>
  </Modal>)
}